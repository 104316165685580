import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

// components
import KnowledgeLinksSection from "./components/KnowledgeLinksSection";
import JoinUsSection from "./components/JoinUsSection";
import CategoriesSection from "./components/CategoriesSection";
import DataLayer from "../shared/DataLayer";
import ItemsSection from "./components/ItemsSection";
import DirectorySection from "./components/DirectorySection";

// redux
import { requestHomeItems } from "../../redux/home/homeActions";

// hooks
import useServerSafeEffect from "../../hooks/useServerSafeEffect";

// helpers
import { HOME_SCHEMA_MARKUP_SCRIPT } from "../../helpers";

// assets
import "../../assets/styles/components/home.scss";

const ITEMS_PAGE_SIZE = 16;
const META_DESCRIPTION = `
  Thousands of genuine antiques for sale, direct from trusted antique dealers.
  Furniture, Jewellery, Silver, Glass, Ceramics, Architectural Antiques, and more.
`;

function Home(props) {
  const { getHomeItems } = props;

  // effects
  useEffect(() => {
    const scriptToAppend = document.createElement("script");
    const homeSchemaScript = document.getElementById("home-schema-script");

    if (homeSchemaScript) {
      return;
    }

    scriptToAppend.type = "application/ld+json";
    scriptToAppend.text = HOME_SCHEMA_MARKUP_SCRIPT;
    document.body.appendChild(scriptToAppend);

    return () => {
      document.body.removeChild(scriptToAppend);
    };
  }, []);
  
  useServerSafeEffect(() => {
    getHomeItems(1, ITEMS_PAGE_SIZE, true, true);
  }, []);

  return (
    <div className="home">
      <DataLayer pageTitle="Antiques For Sale UK - Buy & Sell Antiques Online" />
      <Helmet>
        <title>Antiques For Sale UK - Buy & Sell Antiques Online</title>
        <meta name="description" content={META_DESCRIPTION} />
        <meta name="robots" content="all" />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_URL}${props.location.pathname}`}
        />
      </Helmet>

      <CategoriesSection />
      <ItemsSection />
      <KnowledgeLinksSection />
      <DirectorySection />
      <JoinUsSection />
    </div>
  );
}

Home.propTypes = {
  items: PropTypes.array.isRequired,
};

export default connect((state) => ({ items: state.home.items }), {
  getHomeItems: requestHomeItems,
})(Home);
